import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTiktok,
    faTelegram
} from "@fortawesome/free-brands-svg-icons";

const Socials = () => {
    return (
        <section id="socials">
            <div className="container">
                <div className="inner-container">
                    <div className="social-container">
                        <a href="https://www.tiktok.com/@balancedlife365?_t=8pgi3zS1p9p&_r=1" target="_blank" rel="noopener noreferrer" className="youtube social">
                            <FontAwesomeIcon icon={faTiktok} size="2x" />
                        </a>
                        <a href="https://t.me/+SfH2WPIf2B85Y2Jh" target="_blank" rel="noopener noreferrer" className="facebook social">
                            <FontAwesomeIcon icon={faTelegram} size="2x" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Socials;