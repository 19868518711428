import Navigation from "./Navigation";
import image1 from '../assets/title.png';

const Header =()=>{
    return(
        <section id="header">
            <Navigation />

            <div className="container">
                <div className="main-container">
                </div>
            </div>
        </section>
    );
}

export default Header;