import image1 from '../assets/tralier.png';
import image2 from '../assets/trailer.png';
import image3 from '../assets/dog.png';
import video from "../assets/video/video.mp4";

const GameTrailer = () => {
    return (
        <section id="game-trailer">
            <div className="container">
                <div className="main-game-trailer">
                    <div className="game-trailer-title">
                        <img src={image2} alt="title" />
                    </div>

                    <div className="game-image">

                        <div id='dog-container'>
                            <img src={image3} alt="dog" />
                        </div>

                        <div className="frame">
                            <img src={image1} alt="Frame" className="frame-img" />
                            <video className="video-inside-frame" loop autoPlay muted src={video} />
                        </div>

                        <div className="game">
                            <div className="game-btn">
                                <a href='#' target="_blank" rel="noopener noreferrer"><button>PLAY NOW</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GameTrailer;