import Image1 from '../assets/1.png';
import Image2 from '../assets/2.png';
import Image3 from '../assets/3.png';
import Image4 from '../assets/nft.png';

import Slider from "react-slick";

const NftSlider =()=>{
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true, // Ensures the slides are centered
        centerPadding: "0px", // No padding around the center slide
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              centerMode: true,
              centerPadding: "0px",
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              centerMode: true,
              centerPadding: "0px",
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              centerMode: true,
              centerPadding: "0px",
            }
          }
        ]
      };
      
    return(
        <section id="textimg-section">
            <div className="container">

                <div className='textimg-intro'>
                    <img src={Image4} alt="heading" />
                </div>

            <Slider className="sldier-carousel" {...settings}>
                <div class="text-img-slider">
                    <img src={Image1} />
                </div>
                <div class="text-img-slider">
                    <img src={Image2} />
                </div>
                <div class="text-img-slider">
                    <img src={Image3} />
                </div>
        </Slider>
            </div>
        </section>
    );
}

export default NftSlider;