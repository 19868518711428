import image1 from '../assets/introduction.png';

const About = () => {
    return (
        <section id="about">
            <div className="container">
                <div className="main-aboutcontainer">
                    <div className="aboutus">
                        <div className="about-text">
                            <div className='about-heading'>
                                <img src={image1} alt="introduction" />
                            </div>
                            <p>
                                The digital economy is rapidly evolving, with cryptocurrencies and blockchain technologies at the forefront of this transformation. Balanced-Life365 harnesses these advancements by introducing the BL365 Token—a versatile digital asset engineered to facilitate transactions, reward engagement, and unify various services under a single, cohesive economic framework.
                                BL365 represents a holistic approach to digital engagement, combining entertainment, social interaction, and financial services. This white paper provides an in-depth look into how BL365 integrates into the Balanced-Life365 ecosystem, its strategic tokenomics, and the mechanisms put in place to ensure stability, growth, and user empowerment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;