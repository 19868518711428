import image1 from '../assets/roadmap.png';
import image2 from '../assets/roadmaptext.png';

const Roadmap =()=>{
    return(
        <section id="roadmap">
            <div className="container">
                <div className="roadmap-container">
                    <div className="roadmap-title">
                        <img src={image2} alt="heading" />
                    </div>

                    <div className="roadmap-image">
                        <img src={image1} alt="roadmap" />
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default Roadmap;