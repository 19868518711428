import About from "./About";
import Footer from "./Footer";
import GameTrailer from "./GameTrailer";
import Header from "./Header";
import NftSlider from "./NftSlider";
import Roadmap from "./Roadmap";
import Socials from "./Socials";
import Tokenomics from "./Tokenomics";


const Home = () => {
    return (
        <section>
            <Header />
            <About />
            <GameTrailer />
            <Roadmap />
            <Tokenomics />
            <NftSlider />
            <Footer />
            <Socials />
        </section>
    );
}

export default Home;