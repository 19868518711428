import Image1 from '../assets/Tokenomics.png';
//import Image2 from '../assets/sidelight.png';
import Image3 from '../assets/dog1.png';

import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

const data = [
  { name: "Gaming", value: 20, description: "Rewards and transactions within Balance Pups and other gaming platforms." },
  { name: "Owners", value: 20, description: "Held by the founders and early contributors for strategic development and stability." },
  { name: "Marketing", value: 40, description: "Funding promotional activities, partnerships, and user acquisition initiatives." },
  { name: "Team", value: 10, description: "Compensation and incentives for the development and management team." },
  { name: "Airdrop Pool", value: 10, description: "Distributed through airdrops to incentivize adoption and reward community engagement." }
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#258741"];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

    // Split the description into multiple lines if it's too long
    const description = payload.description || "";
    const descriptionLines = description.length > 40 
      ? [description.slice(0, 40), description.slice(40)]
      : [description];

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="white">
        {payload.name}
      </text>
      {descriptionLines.map((line, index) => (
        <text
          key={`desc-line-${index}`}
          x={cx}
          y={cy + 30 + index * 15} // Position each line below the previous one
          textAnchor="middle"
          fill="white"
          style={{ fontSize: '12px' }}
        >
          {line}
        </text>
      ))}
      <Sector
        style={{ pointerEvents: 'none' }}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke="black"
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill="black" stroke="black" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="white"
      >{/*{`PV ${value}`}*/}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="white"
      >
        {`( ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const Tokenomics = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <section id="tokenomics">

    {/*<div id="sidelight">
      <img src={Image2} alt="spotlight" />
    </div>*/}
    <div id="tokenomics-dog">
      <img src={Image3} alt="dog" />
    </div>

      <div className="container">
        <div className="tokenomics-container">
          <div className="tokenomics-title">
            <img src={Image1} alt="heading" />
          </div>

          <div className="chart-container">
            <PieChart className='piechart' width={600} height={600}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%" // Center the chart horizontally
                cy="50%" // Center the chart vertically
                innerRadius={150}
                outerRadius={170}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tokenomics;