import logo from '../assets/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import pdf from '../whitepaper/whitepaper.pdf';


const Navigation = () => {

  const handleClick = (e, sectionId) => {
    e.preventDefault(); // Prevent the default anchor tag behavior
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
        offset: -70,
      });
    }
  };

  return (

    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="mb-3 navbarBg" style={{ background: 'transparent;' }}>
          <Container fluid>
            <Navbar.Brand href="#" id="logo-heading"><img className='inline' src={logo} alt='' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header id='close' closeButton>
                <Offcanvas.Title className='mbl-title' id={`offcanvasNavbarLabel-expand-${expand}`}>
                  BALANCED PUP
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto links">
                  <Nav.Link className="nav-items text-nowrap" href="#header" onClick={(e) => handleClick(e, 'header')}>Home</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#about" onClick={(e) => handleClick(e, 'about')}>Vision</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#game-trailer" onClick={(e) => handleClick(e, 'game-trailer')}>Trailer</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#roadmap" onClick={(e) => handleClick(e, 'roadmap')}>Roadmap</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href={pdf} target='_blank'>Whitepaper</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" id='mobile-link' href='https://presale.balancedpups.net/' target='_blank'>PRESALE</Nav.Link>
                  <div className="nav-items text-nowrap play-btn">
                    <a href='https://presale.balancedpups.net/' target="_blank" rel="noopener noreferrer"><button>PRESALE</button></a>
                  </div>
                </Nav>

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;


/*
import logo from '../assets/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import pdf from '../whitepaper/whitepaper.pdf';
import { useState } from 'react';

const Navigation = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false);
  };

  const handleClick = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = document.querySelector('.navbar').offsetHeight;
      window.scrollTo({
        top: section.offsetTop - navbarHeight,
        behavior: 'smooth',
      });
    }
    handleOffcanvasClose(); // Close the offcanvas menu
  };

  return (
    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="mb-3 navbarBg" style={{ background: 'transparent;' }}>
          <Container fluid>
            <Navbar.Brand href="#" id="logo-heading"><img className='inline' src={logo} alt='' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowOffcanvas(!showOffcanvas)} />
            <Offcanvas
              show={showOffcanvas}
              onHide={handleOffcanvasClose}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='mbl-title'>
                  DARK <span style={{ color: '#DF010B', fontSize: '40px' }}>QUEEN</span> DUCK
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto links">
                  <Nav.Link className="nav-items text-nowrap" href="#header" onClick={(e) => handleClick(e, 'header')}>Home</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#about" onClick={(e) => handleClick(e, 'about')}>Vision</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#game-trailer" onClick={(e) => handleClick(e, 'game-trailer')}>Trailer</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#roadmap" onClick={(e) => handleClick(e, 'roadmap')}>Roadmap</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href={pdf} target='_blank'>Whitepaper</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;*/

/*import logo from '../assets/logo.png';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import pdf from '../whitepaper/whitepaper.pdf';
import { Link } from 'react-scroll';

const Navigation = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false);
  };

  return (
    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="mb-3 navbarBg" style={{ background: 'transparent;' }}>
          <Container fluid>
            <Navbar.Brand href="#" id="logo-heading"><img className='inline' src={logo} alt='' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowOffcanvas(!showOffcanvas)} />
            <Offcanvas
              show={showOffcanvas}
              onHide={handleOffcanvasClose}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='mbl-title'>
                  DARK <span style={{ color: '#DF010B', fontSize: '40px' }}>QUEEN</span> DUCK
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto links">
                  <Link
                    to="header"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-80}
                    onClick={handleOffcanvasClose} // Close the menu when a link is clicked
                    className="nav-link"
                  >
                    Home
                  </Link>
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-80}
                    onClick={handleOffcanvasClose} // Close the menu when a link is clicked
                    className="nav-link"
                  >
                    Vision
                  </Link>
                  <Link
                    to="game-trailer"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-80}
                    onClick={handleOffcanvasClose} // Close the menu when a link is clicked
                    className="nav-link"
                  >
                    Trailer
                  </Link>
                  <Link
                    to="roadmap"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-80}
                    onClick={handleOffcanvasClose} // Close the menu when a link is clicked
                    className="nav-link"
                  >
                    Roadmap
                  </Link>
                  <Nav.Link className="nav-items text-nowrap nav-link" href={pdf} target='_blank'>Whitepaper</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;*/